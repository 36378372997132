import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import WhatWeDo2 from '../images/structure.jpg'
import { Link } from 'react-router-dom'

export default function Structural() {

	return (
		<>
			<Header bannerStyle={'white-navbar'} />

			<main className='services-wrap'>
				<section className='whatwedo-hero-wrap '>
					<div className='container'>
						<div className='whatwedo-page-header'>
						<span className='text-accent inner-page-subtitle'>Structural Deterioration</span>
							<h1 className='whatwedo-page-title'>
								Understanding Structural Deterioration.
							</h1>
						</div>
					</div>
				</section>

				<section className='services-section-wrap section-wrap'>
					<div className="container services-section-grid">
						<div>

							<div className="services-header-wrap">
								<h3 className='section-title'>
									Structural Deterioration
								</h3>
							</div>
							<div className='services-body'>
								<p className='services-body-text'>
									Structural deterioration can be caused by various factors such as aging, environmental conditions, poor maintenance, and design flaws. It can manifest in different forms including cracks, corrosion, settlement, and deformation.
								</p>
								<div className='services-section-img'>
									<img src={WhatWeDo2} alt="" />
								</div>
								<p className='services-body-text'>
									To determine the appropriate repair recommendation, a thorough assessment of the specific structural issue is necessary. This may involve inspections, testing, and analysis by qualified professionals.
									Based on the assessment, the repair recommendation will depend on the severity and nature of the deterioration. It could range from minor repairs such as patching cracks or applying protective coatings, to more extensive measures like reinforcement, replacement of damaged components, or even structural retrofitting.
								</p>
								<p className='services-body-text'>
									It is crucial to follow industry standards, codes, and regulations when implementing repairs to ensure the structural integrity and safety of the building or infrastructure. Engaging experienced professionals with expertise in structural repairs is highly recommended to ensure the effectiveness and longevity of the repair solution.
								</p>
							</div>
						</div>

						<div className='services-moreservice-wrap'>
							<h5 className='services-moreservice-title'>More Services</h5>
							<ul className='services-moreservice-body'>
								<li><Link to={'/leakages-facade'}>Leakages in Facade</Link></li>
								<li><Link to={'/cracks-plaster'}>Cracks in plaster</Link></li>
								<li><Link to={'/waterproofing-systems'}>Waterproofing Systems</Link></li>
								<li><Link to={'/plumbing-defects'}>Plumbing Defects</Link></li>
								<li><Link to={'/painting'}>Painting</Link></li>
							</ul>
						</div>

					</div>
				</section>


			</main>

			<Footer />
		</>
	)
}
