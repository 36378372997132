import React, { useEffect, useState } from 'react'
// import React from 'react'
import LogoBlue from '../images/tac-logo-blue.svg'
import LogoWhite from '../images/tac-white.svg'

import { Link, useLocation, useNavigate } from 'react-router-dom'

export default function Header({ bannerStyle }) {
  const location = useLocation();
  const navigate = useNavigate();



  useEffect(() => {
    window.scroll({ top: 0, behavior: 'instant' })
  }, [location]);


  const [scroll, setscroll] = useState('')
  const [scrollPos, setscrollPos] = useState(0)

  // const [bannerType, setBannerType] = useState(bannerStyle)

  const [showNavbar, setShowNavbar] = useState(false)
  const [windowSize, setwindowSize] = useState(0)


  const handleScroll = () => {
    const scrollPosition = window.scrollY; // => scroll position
    setscrollPos(scrollPosition);
  };
  useEffect(() => {


    if (window.innerWidth >= 991) {
      setwindowSize(991)
    } else {
      setwindowSize(0)

    }
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };



  }, []);



  var root = document.getElementsByTagName('html')[0]; // '0' to assign the first (and only `HTML` tag)
  var rootBody = document.getElementsByTagName('body')[0]; // '0' to assign the first (and only `HTML` tag)


  if (bannerStyle) {
    root.style.overflowX = 'unset'
    rootBody.style.overflowX = 'unset'
  } else {

    root.style.overflowX = ''
    rootBody.style.overflowX = ''

  }

  useEffect(() => {

    if (windowSize >= 991) {
      root.setAttribute('class', '');
      document.body.classList.remove('hide-scroll')
      setShowNavbar(false)
    } else {
      if (showNavbar) {
        root.setAttribute('class', 'hide-scroll');
        document.body.classList.add('hide-scroll')
      } else {
        root.setAttribute('class', '');
        document.body.classList.remove('hide-scroll')

      }
    }

  }, [root, windowSize, showNavbar])


  window.onresize = function () {
    setwindowSize(window.innerWidth)
  }

  window.onscroll = function () {
    if (scrollPos > 50) {
      setscroll('scroll')
    } else {
      setscroll('')
    }
  };

  return (
    <header>
      <nav className={`navbar navbar-wrap fixed-top navbar-expand-lg ${scroll} ${bannerStyle && scroll === '' ? bannerStyle : ''}`} id='navbar-wrap'>
        <div className="container">
          <a href="void(0)" className="navbar-brand">
            {
              bannerStyle && scroll === '' ?
                <img src={LogoWhite} alt="navbar logo" />
                :
                <img src={LogoBlue} alt="navbar logo" />

            }

            {/* <img src={LogoBlue} alt="navbar logo" /> */}
            <span className={`navbar-brand-text position-relative fw-bold ${bannerStyle && scroll === '' ? 'text-light' : ''}`}>Tech Advise
              <i className='logo-registered'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM160 152V264v96c0 13.3 10.7 24 24 24s24-10.7 24-24V288h60.9l37.2 81.9c5.5 12.1 19.7 17.4 31.8 11.9s17.4-19.7 11.9-31.8L315.7 275c21.8-14.3 36.3-39 36.3-67c0-44.2-35.8-80-80-80H184c-13.3 0-24 10.7-24 24zm48 88V176h64c17.7 0 32 14.3 32 32s-14.3 32-32 32H208z" /></svg>
              </i> <br /> <span className="text-accent"> Consultants</span>
            </span>

          </a>
          <button onClick={() => setShowNavbar(!showNavbar)} className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent">
            <div id="nav-icon3" className={`${showNavbar ? 'open' : ''}`} >
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </button>
          <div className={`collapse navbar-collapse show ${windowSize >= 991 ? '' : showNavbar ? 'navbar-visible' : 'navbar-hidden'} `} id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className={`nav-item  ${windowSize >= 991 ? '' : 'container'}`}>
                <Link to='/' className="nav-link">Home</Link>
              </li>
              <li className={`nav-item  ${windowSize >= 991 ? '' : 'container'}`}>
                <Link to='/who-we-are' className="nav-link">Who we are</Link>
              </li>
              <li className={`nav-item  ${windowSize >= 991 ? '' : 'container'}`}>
                <Link to='/what-we-do' className="nav-link">What we do</Link>
              </li>
              <li className={`nav-item  ${windowSize >= 991 ? '' : 'container'}`}>
                {/* <a href='void(0)' className="nav-link">Our Team</a> */}
                <Link to='/our-projects' className="nav-link">Our Projects</Link>
              </li>
              <li className={`nav-item  ${windowSize >= 991 ? '' : 'container'}`}>
                <span onClick={() => {
                  navigate('/');
                  setTimeout(() => {
                    document?.getElementById('contact-us')?.scrollIntoView({ block: 'center' })
                  }, 10)
                }} className="nav-link ">
                  <button className='primary-btn'>
                    Contact Us
                  </button>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  )
}
