import React, { useEffect } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import WhatWeDo2 from '../images/whatweDo2nd.jpg'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'


export default function WhatWeDo() {


  // var slider = document.getElementById('card-slider')


  // useEffect(() => {
  // setInterval(() => {
  // }, 1000);

  // }, [])



  return (
    <>
      <Helmet>
        <title>Tech Advise Consultants | What We Do</title>
        <meta name="description" content="Explore the comprehensive range of services offered by Tech Advise Consultants. From strategic planning to implementation, we specialize in providing tailored consultancy solutions to help you achieve your business goals. Learn how our expertise can drive growth and success for your organization." />

      </Helmet>

      <Header bannerStyle={'white-navbar'} />

      <main className='whatwedo-page-wrap'>
        <section className='whatwedo-hero-wrap '>
          <div className='container'>
            <div className='whatwedo-page-header'>
              <span className='text-accent inner-page-subtitle'>what we do</span>
              <h1 className='whatwedo-page-title'>
                Unveiling Our Impact.
              </h1>
            </div>
          </div>
        </section>

        <section className="whoweare-wrap section-wrap mt-5">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-lg-7 order-1 order-lg-3">
                <div className="whoweare-header-wrap ps-0 ps-lg-4">
                  <h3 className="whoweare-title section-title">
                    About Us
                  </h3>

                  <p className="whoweare-subtitle mb-4 mb-lg-5">
                    As an expert engineers, we are experts in assessing, diagnosing, and providing solutions for various issues related to building structures. We specialize in identifying and addressing problems such as structural damage, foundation issues, deterioration, and other structural deficiencies.
                    <br />
                    <br />
                    We engineers have in-depth knowledge of building materials, construction techniques, and structural analysis and skilled in conducting thorough inspections, using advanced tools and techniques to assess the condition of buildings. Based on these findings, we develop repair plans and recommend appropriate solutions to ensure the safety, stability, and longevity of the structure.
                  </p>
                </div>
              </div>
              <div className="col-12 col-lg-5 order-3 order-lg-1">
                <div className="whoweare-body">
                  <div className="whoweare-img-wrap">
                    <img src={WhatWeDo2} className="whoweare-img" style={{ aspectRatio: 1 / 0.8 }} alt="img of a building in repair" />
                  </div>
                </div>
              </div>
            </div>



          </div>
        </section>


        <section className='ourservices-wrap section-wrap'>
          <div className='container'>
            <div className='ourservices-header-wrap text-center'>
              <h3 className='section-title '>
                Our Expertise
              </h3>
              <p className='section-subtitle'>Know the services we excel in providing to our customers.</p>
            </div>
            <div className="ourservices-body">
              <div className="ourservices-card">
                <div className="ourservices-card-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                    <path
                      d="M64 32H320c17.7 0 32 14.3 32 32V266.8c9.1-12.9 19.9-24.5 32-34.6V64c0-35.3-28.7-64-64-64H64C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64h64 32 64 32 64c19.5 0 37-8.7 48.7-22.5c-7.7-8-14.6-16.8-20.6-26.1c-5.4 9.9-16 16.6-28.1 16.6H256V416c0-35.3-28.7-64-64-64s-64 28.7-64 64v64H64c-17.7 0-32-14.3-32-32V64c0-17.7 14.3-32 32-32zM224 416v64H160V416c0-17.7 14.3-32 32-32s32 14.3 32 32zM64 120v48c0 13.3 10.7 24 24 24h48c13.3 0 24-10.7 24-24V120c0-13.3-10.7-24-24-24H88c-13.3 0-24 10.7-24 24zm32 8h32v32H96V128zM248 96c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h48c13.3 0 24-10.7 24-24V120c0-13.3-10.7-24-24-24H248zm8 64V128h32v32H256zM64 248v48c0 13.3 10.7 24 24 24h48c13.3 0 24-10.7 24-24V248c0-13.3-10.7-24-24-24H88c-13.3 0-24 10.7-24 24zm32 8h32v32H96V256zm152-32c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h48c13.3 0 24-10.7 24-24V248c0-13.3-10.7-24-24-24H248zm8 64V256h32v32H256zm128 80a112 112 0 1 1 224 0 112 112 0 1 1 -224 0zm256 0a144 144 0 1 0 -288 0 144 144 0 1 0 288 0zM496 448a24 24 0 1 0 0-48 24 24 0 1 0 0 48zm0-160c-8.8 0-16 7.2-16 16v64c0 8.8 7.2 16 16 16s16-7.2 16-16V304c0-8.8-7.2-16-16-16z" />
                  </svg>
                </div>
                <div className="ourservices-card-body">
                  <h6 className='ourservices-card-title'>Structural Deterioration</h6>
                  <p className='ourservices-card-subtitle'>Structural deterioration can be caused by various factors such as aging, environmental conditions, poor maintenance, and design flaws.</p>
                  <Link to="/structural-deterioration" className='ourservices-card-link'>Learn More</Link>
                </div>
              </div>
              <div className="ourservices-card">
                <div className="ourservices-card-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                    <path
                      d="M298.6 4c-6-5.3-15.1-5.3-21.2 0L5.4 244c-6.6 5.8-7.3 16-1.4 22.6s16 7.3 22.6 1.4L64 235V393c9.9-5.7 20.9-8.6 32-8.6V206.7L288 37.3 480 206.7V384.5c11.1 0 22.1 2.9 32 8.6V235l37.4 33c6.6 5.8 16.7 5.2 22.6-1.4s5.2-16.7-1.4-22.6L298.6 4zM208 208v96c0 17.7 14.3 32 32 32h96c17.7 0 32-14.3 32-32V208c0-17.7-14.3-32-32-32H240c-17.7 0-32 14.3-32 32zm128 0v96H240V208h96zM192 480c-20.9 0-41.7-9.9-58.2-21.2c-9.7-6.6-18-14-25.9-21.9c-6.6-6.5-17.2-6.5-23.8 0c-7.9 7.9-16.1 15.2-25.9 21.9c-12.8 8.7-28.1 16.6-44 19.8C6.4 480.1 0 486.4 0 494.2c0 9.8 8 17.9 17.7 16.2c23.1-4 43.6-15 58.5-25.2c7.9-5.4 14.6-10.8 19.8-15.3c5.1 4.5 11.9 9.9 19.8 15.3C134.4 497.9 161.7 512 192 512s57.5-14.1 76.2-26.8c7.9-5.4 14.6-10.8 19.8-15.3c5.1 4.5 11.9 9.9 19.8 15.3C326.4 497.9 353.7 512 384 512s57.5-14.1 76.2-26.8c7.9-5.4 14.6-10.8 19.8-15.3c5.1 4.5 11.9 9.9 19.8 15.3c15 10.2 35.4 21.2 58.5 25.2c9.7 1.7 17.7-6.4 17.7-16.2c0-7.9-6.4-14.1-14.1-15.7c-16-3.1-31.3-11.1-44.1-19.8c-9.8-6.7-18-14-26-21.9c-6.6-6.5-17.2-6.5-23.7 0c-7.9 7.9-16.1 15.2-25.9 21.9C425.6 470 404.9 480 384 480s-41.7-9.9-58.2-21.2c-9.8-6.7-18-14-26-21.9c-6.6-6.5-17.2-6.5-23.7 0c-7.9 7.9-16.1 15.2-25.9 21.9C233.6 470 212.9 480 192 480z" />
                  </svg>
                </div>
                <div className="ourservices-card-body">
                  <h6 className='ourservices-card-title'>Leakages in Facade</h6>
                  <p className='ourservices-card-subtitle'>Leakage in a facade can have various causes, and identifying the specific cause is crucial for determining the appropriate repair</p>
                  <Link to="/leakages-facade" className='ourservices-card-link'>Learn More</Link>
                </div>
              </div>
              <div className="ourservices-card">
                <div className="ourservices-card-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                    <path
                      d="M298.6 4c-6-5.3-15.1-5.3-21.2 0L5.4 244c-6.6 5.8-7.3 16-1.4 22.6s16 7.3 22.6 1.4L64 235V432c0 44.2 35.8 80 80 80H432c44.2 0 80-35.8 80-80V235l37.4 33c6.6 5.8 16.7 5.2 22.6-1.4s5.2-16.7-1.4-22.6L298.6 4zM96 432V206.7L288 37.3 480 206.7V432c0 26.5-21.5 48-48 48H312.6l-50.4-75.6 82.7-55.1c4-2.7 6.6-7 7-11.7s-1.2-9.5-4.6-12.9l-96-96c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6l82.2 82.2-79.8 53.2c-7.4 4.9-9.3 14.8-4.4 22.2L274.1 480H144c-26.5 0-48-21.5-48-48z" />
                  </svg>
                </div>
                <div className="ourservices-card-body">
                  <h6 className='ourservices-card-title'>Cracks in plaster</h6>
                  <p className='ourservices-card-subtitle'>Cracks in plaster can occur due to a variety of reasons, including settlement, moisture, temperature fluctuations, or structural movement.</p>
                  <Link to="/cracks-plaster" className='ourservices-card-link'>Learn More</Link>

                </div>
              </div>
              <div className="ourservices-card">
                <div className="ourservices-card-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                    <path
                      d="M176.1 99.3C178.6 61.7 209.8 32 248 32c26.4 0 49.4 14.2 62 35.4c2.3 3.8 6 6.5 10.3 7.5s8.9 .1 12.5-2.4c7.7-5.3 17-8.4 27.2-8.4c23 0 42.2 16.1 46.9 37.7c1.6 7.3 8.1 12.6 15.6 12.6h18.6c21.5 0 38.9 17.4 38.9 38.9s-17.4 38.9-38.9 38.9H134.9C113.4 192 96 174.6 96 153.1s17.4-38.9 38.9-38.9h25.3c8.4 0 15.4-6.6 16-15zM248 0c-50 0-91.7 35.3-101.7 82.3H134.9C95.7 82.3 64 114 64 153.1s31.7 70.9 70.9 70.9H441.1c39.1 0 70.9-31.7 70.9-70.9s-31.7-70.9-70.9-70.9h-6.8C422.5 52.8 393.7 32 360 32c-11.1 0-21.7 2.3-31.3 6.4C309.6 15 280.6 0 248 0zM141.3 280.9c4.9-7.4 2.9-17.3-4.4-22.2s-17.3-2.9-22.2 4.4l-64 96c-4.9 7.4-2.9 17.3 4.4 22.2s17.3 2.9 22.2-4.4l64-96zM192 480c-20.9 0-41.7-9.9-58.2-21.2c-9.7-6.6-18-14-25.9-21.9c-6.6-6.5-17.2-6.5-23.8 0c-7.9 7.9-16.1 15.2-25.9 21.9c-12.8 8.7-28.1 16.6-44 19.8C6.4 480.1 0 486.4 0 494.2c0 9.8 8 17.9 17.7 16.2c23.1-4 43.6-15 58.5-25.2c7.9-5.4 14.6-10.8 19.8-15.3c5.1 4.5 11.9 9.9 19.8 15.3C134.4 497.9 161.7 512 192 512s57.5-14.1 76.2-26.8c7.9-5.4 14.6-10.8 19.8-15.3c5.1 4.5 11.9 9.9 19.8 15.3C326.4 497.9 353.7 512 384 512s57.5-14.1 76.2-26.8c7.9-5.4 14.6-10.8 19.8-15.3c5.1 4.5 11.9 9.9 19.8 15.3c15 10.2 35.4 21.2 58.5 25.2c9.7 1.7 17.7-6.4 17.7-16.2c0-7.9-6.4-14.1-14.1-15.7c-16-3.1-31.3-11.1-44.1-19.8c-9.8-6.7-18-14-26-21.9c-6.6-6.5-17.2-6.5-23.7 0c-7.9 7.9-16.1 15.2-25.9 21.9C425.6 470 404.9 480 384 480s-41.7-9.9-58.2-21.2c-9.8-6.7-18-14-26-21.9c-6.6-6.5-17.2-6.5-23.7 0c-7.9 7.9-16.1 15.2-25.9 21.9C233.6 470 212.9 480 192 480zM472.9 258.7c-7.4-4.9-17.3-2.9-22.2 4.4l-64 96c-4.9 7.4-2.9 17.3 4.4 22.2s17.3 2.9 22.2-4.4l64-96c4.9-7.4 2.9-17.3-4.4-22.2zM253.3 280.9c4.9-7.4 2.9-17.3-4.4-22.2s-17.3-2.9-22.2 4.4l-64 96c-4.9 7.4-2.9 17.3 4.4 22.2s17.3 2.9 22.2-4.4l64-96zm107.6-22.2c-7.4-4.9-17.3-2.9-22.2 4.4l-64 96c-4.9 7.4-2.9 17.3 4.4 22.2s17.3 2.9 22.2-4.4l64-96c4.9-7.4 2.9-17.3-4.4-22.2z" />
                  </svg>
                </div>
                <div className="ourservices-card-body">
                  <h6 className='ourservices-card-title'>Waterproofing Systems</h6>
                  <p className='ourservices-card-subtitle'>Waterproofing of podiums and terraces is essential to protect the underlying structure from water damage, leaks, and moisture infiltration.</p>
                  <Link to="/waterproofing-systems" className='ourservices-card-link'>Learn More</Link>

                </div>
              </div>
              <div className="ourservices-card">
                <div className="ourservices-card-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path
                      d="M272 32c0-8.8-7.2-16-16-16s-16 7.2-16 16V80H144c-8.8 0-16 7.2-16 16s7.2 16 16 16h96v80H32V176c0-8.8-7.2-16-16-16s-16 7.2-16 16v32V432v32c0 8.8 7.2 16 16 16s16-7.2 16-16V448H480v16c0 8.8 7.2 16 16 16s16-7.2 16-16V432 208 176c0-8.8-7.2-16-16-16s-16 7.2-16 16v16H272V112h96c8.8 0 16-7.2 16-16s-7.2-16-16-16H272V32zM256 224H480V416H32V224H256z" />
                  </svg>
                </div>
                <div className="ourservices-card-body">
                  <h6 className='ourservices-card-title'>Plumbing Defects</h6>
                  <p className='ourservices-card-subtitle'>Plumbing leakage / defects can occur due to various reasons such as faulty installation, aging pipes, high water pressure, corrosion, or damage to pipe joints.</p>
                  <Link to="/plumbing-defects" className='ourservices-card-link'>Learn More</Link>
                </div>
              </div>
              <div className="ourservices-card">
                <div className="ourservices-card-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                    <path
                      d="M256 352c-17.7 0-32 14.3-32 32v64c0 17.7-14.3 32-32 32s-32-14.3-32-32V384c0-17.7-14.3-32-32-32H80c-26.5 0-48-21.5-48-48V288H352v16c0 26.5-21.5 48-48 48H256zm96-96H32V64c0-17.7 14.3-32 32-32H96V144c0 8.8 7.2 16 16 16s16-7.2 16-16V32h64v80c0 8.8 7.2 16 16 16s16-7.2 16-16V32h96c17.7 0 32 14.3 32 32V256zM80 384H96h32v32 32c0 35.3 28.7 64 64 64s64-28.7 64-64V416 384h32 16c44.2 0 80-35.8 80-80V64c0-35.3-28.7-64-64-64H64C28.7 0 0 28.7 0 64V304c0 44.2 35.8 80 80 80z" />
                  </svg>
                </div>
                <div className="ourservices-card-body">
                  <h6 className='ourservices-card-title'>Painting</h6>
                  <p className='ourservices-card-subtitle'>Paint defects can occur due to various reasons such as improper surface preparation, application errors, environmental factors, or product quality issues.</p>
                  <Link to="/painting" className='ourservices-card-link'>Learn More</Link>
                </div>
              </div>
            </div>
          </div>
        </section>


        <section className='benefits-wrap  section-wrap'>
          <div className="container">

            <div className='benefits-header-wrap'>
              <h3 className='section-title'>
                Why have a Consultant?
              </h3>
              <p className='section-subtitle'>
                Working with building repair engineers offers several benefits and ease in addressing structural issues and maintaining the integrity of buildings.
              </p>
            </div>
            <div className='benefits-content' id='card-slider'>
              <div className="benefits-card">
                <div className='benefits-card-content'>
                  <h5 className='benefits-card-title'>Expertise and Knowledge.</h5>
                  <p className='benefits-card-subtitle'>Building repair engineers possess specialized knowledge and expertise in assessing and addressing structural problems.</p>
                </div>
              </div>
              <div className="benefits-card">
                <div className='benefits-card-content'>
                  <h5 className='benefits-card-title'>Comprehensive Inspections.</h5>
                  <p className='benefits-card-subtitle'>Building repair engineers conduct thorough inspections to identify underlying issues and potential risks. </p>
                </div>
              </div>
              <div className="benefits-card">
                <div className='benefits-card-content'>
                  <h5 className='benefits-card-title'>Tailored Solutions.</h5>
                  <p className='benefits-card-subtitle'>Building repair engineers develop customized repair plans based on the specific needs and requirements of the building</p>
                </div>
              </div>
              <div className="benefits-card">
                <div className='benefits-card-content' >
                  <h5 className='benefits-card-title'>Compliance with Regulations</h5>
                  <p className='benefits-card-subtitle'>Building repair engineers are well-versed in building codes, regulations, and industry standards.</p>
                </div>
              </div>

              <div className="benefits-card">
                <div className='benefits-card-content'>
                  <h5 className='benefits-card-title'>Project Management</h5>
                  <p className='benefits-card-subtitle'>often take on project management roles, coordinating with architects, contractors, and other professionals involved in the repair process.</p>
                </div>
              </div>
              <div className="benefits-card">
                <div className='benefits-card-content'>
                  <h5 className='benefits-card-title'>Cost Control and Budgeting</h5>
                  <p className='benefits-card-subtitle'>Consultants in building repairs help clients optimize costs by providing accurate cost estimates, analyzing alternatives, and recommending cost-effective solutions.</p>
                </div>
              </div>
              <div className="benefits-card">
                <div className='benefits-card-content'>
                  <h5 className='benefits-card-title'>Quality Assurance</h5>
                  <p className='benefits-card-subtitle'> prioritize quality assurance to ensure that the repairs are done to the highest standards. </p>
                </div>
              </div>
              <div className="benefits-card">
                <div className='benefits-card-content' id='ld'>
                  <h5 className='benefits-card-title'>Long-Term Durability</h5>
                  <p className='benefits-card-subtitle'>By addressing structural issues promptly and effectively, building repair engineers help ensure the long-term durability and stability of the building.</p>
                </div>
              </div>
            </div>
            <div className='benefits-button-wrap mt-4'>
              <button className='primary-btn me-2 rounded-pill ' onClick={() =>
                document.getElementById('card-slider').scrollLeft -= 290
              }>
                Prev
              </button>
              <button className='primary-btn rounded-pill '
                onClick={() =>
                  document.getElementById('card-slider').scrollLeft += 290
                }
              >
                Next
              </button>
            </div>
          </div>

        </section>

        <section className='stagesofwork-wrap section-wrap'>
          <div className="container">

            <div className='stagesofwork-header-wrap'>
              <h3 className='section-title text-center'>
                Stages of Work
              </h3>
            </div>

            <div className='stagesofwork-content'>

              <div className='stagesofwork-body'>

                <div className='stagesofwork-card'>
                  <p className='stagesofwork-subtitle'>Stage 1</p>
                  <h6 className='stagesofwork-title'>
                    Survey and Preparation of Tender
                  </h6>

                  <div className='stagesofwork-body'>
                    <ul>
                      <li>
                        Determining the importance of various scope aspects of structural repairs and aesthetics
                      </li>
                      <li>
                        Thorough internal and external surveys of all areas for accurate preliminary surveys.
                      </li>
                      <li>
                        Comprehensive detailed budget estimate with category-specific costs.
                      </li>
                    </ul>
                  </div>
                </div>

                <div className='stagesofwork-card'>
                  <p className='stagesofwork-subtitle'>Stage 2</p>
                  <h6 className='stagesofwork-title'>
                    Preparation of tender, invitation of bids and finalization of contractor.
                  </h6>

                  <div className='stagesofwork-body'>
                    <ul>
                      <li>Preparing tender document based on the final budgeted estimate covering all the legal, technical and the financial aspects of the repairs of the concerned building.
                      </li>
                      <li>
                        Inviting bids from competent contractors.
                      </li>
                      <li>
                        Various bids thus received are evaluated on techno-economic basis.
                      </li>

                      <li>
                        Conducting Technical meet with contractors.
                      </li>
                      <li>
                        Financial Evaluation and Negotiations with contractors.
                      </li>
                      <li>
                        Finalisation of contractor with the consent of client.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='stagesofwork-card'>
                  <p className='stagesofwork-subtitle'>Stage 3</p>
                  <h6 className='stagesofwork-title'>
                    Execution of Work.
                  </h6>

                  <div className='stagesofwork-body'>
                    <p>
                      A three-tier system has been devised to achieve the best-desired results during the execution stage, once the contractor is finalized.
                    </p>
                    <ul>
                      <li>
                        To execute a job, the day-to-day functioning including checking various mixes being used, stock of the material received, testing of materials, liasoning between the members and the contractors etc.
                      </li>
                      <li>
                        Engineer visits the site at suitable intervals depending upon site conditions Joint measurements, scrutiny of bill and preparation of payment certificate.
                      </li>
                      <li>
                        Conducting fortnightly meetings along with client to review the progress of work with the contractor.
                        Coordinating with site engineers and supervisors to guarantee top-quality work within budget constraints.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='stagesofwork-card'>
                  <p className='stagesofwork-subtitle'>Stage 4</p>
                  <h6 className='stagesofwork-title'>
                    Finalisation of Work
                  </h6>

                  <div className='stagesofwork-body'>
                    <ul>
                      <li>
                        Collecting the Performa Warranty, which outlines the terms and conditions of product or service guarantees.
                      </li>
                      <li>
                        Additionally, obtaining the Work Completion Certificate is essential, as it signifies the successful conclusion of the project and adherence to agreed-upon specifications.
                      </li>
                      <li>
                        To ensure longevity, collecting the Warranty Certificate from the paint company is vital, validating the quality and durability of applied coatings.
                      </li>
                      <li>
                        Lastly, the collection of the Retention Revert Letter marks the release of withheld funds, cementing the financial closure of the project.
                      </li>
                    </ul>
                  </div>
                </div>

              </div>

            </div>
          </div>

        </section>


      </main>
      <Footer />
    </>
  )
}
