import React, { useRef, useState } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Whoweareimg from '../images/2.jpg'
import { useNavigate } from 'react-router-dom'
import emailjs from '@emailjs/browser';
import Modal from '../../components/Modal'
import HomeBanner from '../images/homeBanner.jpg'


export default function Home() {
  const navigate = useNavigate();

  const [formContent, setformContent] = useState({
    name: '',
    email: '',
    message: ''
  })

  const [modalConfig, setModalConfig] = useState({
    isOpen: false,
    type: 'success'
  })


  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();



    emailjs.sendForm('service_yo9wrt9', 'template_podd10j', form.current, 'mNowicCdhA663AjKz')
      .then((result) => {

        setformContent({
          name: '',
          email: '',
          message: ''
        })

        setModalConfig({ isOpen: true, type: 'success' });

        setTimeout(() => (
          setModalConfig({ isOpen: false })
        ), 1000)

      }, (error) => {

        setModalConfig({ isOpen: true, type: 'fail' })

        setTimeout(() => (
          setModalConfig({ isOpen: false })
        ), 2500)


      });
  };



  return (
    <>
      <Header />
      <main>

        {/* <!-- Hero Section --> */}

        <section className="hero-wrap">
          <div className="container">
            <div className="hero-grid">
              <div className="hero-header-wrap">
                <h1 className="hero-title">
                  Engineering Solutions and Consultations
                  for <span className="text-accent fw-bolder"> Housing Societies. </span>

                </h1>
                <p className="hero-subtitle">
                  Tech Advise Consultants is a team of Engineers and Architect that specializes in offering
                  accurate and thorough technical and commercial consultation to co-operative
                  housing societies for repairs, waterproofing systems, painting, and
                  redevelopment projects.

                </p>

                <div className="hero-btn-wrap">
                  <button onClick={() => {
                    document?.getElementById('contact-us')?.scrollIntoView({ block: 'center' })
                  }} className="primary-btn mb-3  text-light">
                    Contact Us
                  </button>

                  <button className="accent-btn text-center  w-lg-none" onClick={() => navigate('/what-we-do')}>
                    <span className="hero-link-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                        <path
                          d="M443.3 267.3c6.2-6.2 6.2-16.4 0-22.6l-176-176c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6L393.4 240 16 240c-8.8 0-16 7.2-16 16s7.2 16 16 16l377.4 0L244.7 420.7c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0l176-176z" />
                      </svg>
                    </span>
                    Know More
                  </button>

                </div>

              </div>

              <div className="hero-img-wrap ">
                <img className="hero-img" src={HomeBanner}
                  alt="" />
              </div>
            </div>
          </div>
        </section>

        {/* <!-- what we do --> */}
        <section className="whatwedo-wrap section-wrap">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-lg-6">
                <div className="whatwedo-header-wrap mb-5 mb-lg-0 pe-0 pe-lg-4">
                  <h2 className="whatwedo-title section-title">What We Do</h2>

                  <p className="whtawedo-subtitle">
                    In Tech Advise Consultant, our goal is to provide our clients with a complete
                    repair, rehabilitation, and redevelopment solution for various problems in their
                    building.
                  </p>
                  <br />
                  <p className='mb-5'>
                    We have in-depth knowledge of building materials, construction techniques, and
                    structural analysis and skilled in conducting thorough inspections, using advanced tools and
                    techniques to assess the condition of buildings. Based on these findings, we develop repair
                    plans and recommend appropriate solutions to ensure the safety, stability, and longevity of
                    the structure.
                  </p>

                  <button onClick={() => navigate('/what-we-do')} className='primary-btn'>
                    Know More
                  </button>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="whatwedo-body">
                  <ul className="whatwedo-list-wrap">

                    <li className="whatwedo-list-item" onClick={() => navigate('/structural-deterioration')}>
                      <span className="whatwedo-list-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                          <path
                            d="M64 32H320c17.7 0 32 14.3 32 32V266.8c9.1-12.9 19.9-24.5 32-34.6V64c0-35.3-28.7-64-64-64H64C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64h64 32 64 32 64c19.5 0 37-8.7 48.7-22.5c-7.7-8-14.6-16.8-20.6-26.1c-5.4 9.9-16 16.6-28.1 16.6H256V416c0-35.3-28.7-64-64-64s-64 28.7-64 64v64H64c-17.7 0-32-14.3-32-32V64c0-17.7 14.3-32 32-32zM224 416v64H160V416c0-17.7 14.3-32 32-32s32 14.3 32 32zM64 120v48c0 13.3 10.7 24 24 24h48c13.3 0 24-10.7 24-24V120c0-13.3-10.7-24-24-24H88c-13.3 0-24 10.7-24 24zm32 8h32v32H96V128zM248 96c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h48c13.3 0 24-10.7 24-24V120c0-13.3-10.7-24-24-24H248zm8 64V128h32v32H256zM64 248v48c0 13.3 10.7 24 24 24h48c13.3 0 24-10.7 24-24V248c0-13.3-10.7-24-24-24H88c-13.3 0-24 10.7-24 24zm32 8h32v32H96V256zm152-32c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24h48c13.3 0 24-10.7 24-24V248c0-13.3-10.7-24-24-24H248zm8 64V256h32v32H256zm128 80a112 112 0 1 1 224 0 112 112 0 1 1 -224 0zm256 0a144 144 0 1 0 -288 0 144 144 0 1 0 288 0zM496 448a24 24 0 1 0 0-48 24 24 0 1 0 0 48zm0-160c-8.8 0-16 7.2-16 16v64c0 8.8 7.2 16 16 16s16-7.2 16-16V304c0-8.8-7.2-16-16-16z" />
                        </svg>
                      </span>
                      <h6 className="whatwedo-list-title">
                        Structural Deterioration
                      </h6>
                    </li>
                    <li className="whatwedo-list-item" onClick={() => navigate('/leakages-facade')}>
                      <span className="whatwedo-list-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                          <path
                            d="M298.6 4c-6-5.3-15.1-5.3-21.2 0L5.4 244c-6.6 5.8-7.3 16-1.4 22.6s16 7.3 22.6 1.4L64 235V393c9.9-5.7 20.9-8.6 32-8.6V206.7L288 37.3 480 206.7V384.5c11.1 0 22.1 2.9 32 8.6V235l37.4 33c6.6 5.8 16.7 5.2 22.6-1.4s5.2-16.7-1.4-22.6L298.6 4zM208 208v96c0 17.7 14.3 32 32 32h96c17.7 0 32-14.3 32-32V208c0-17.7-14.3-32-32-32H240c-17.7 0-32 14.3-32 32zm128 0v96H240V208h96zM192 480c-20.9 0-41.7-9.9-58.2-21.2c-9.7-6.6-18-14-25.9-21.9c-6.6-6.5-17.2-6.5-23.8 0c-7.9 7.9-16.1 15.2-25.9 21.9c-12.8 8.7-28.1 16.6-44 19.8C6.4 480.1 0 486.4 0 494.2c0 9.8 8 17.9 17.7 16.2c23.1-4 43.6-15 58.5-25.2c7.9-5.4 14.6-10.8 19.8-15.3c5.1 4.5 11.9 9.9 19.8 15.3C134.4 497.9 161.7 512 192 512s57.5-14.1 76.2-26.8c7.9-5.4 14.6-10.8 19.8-15.3c5.1 4.5 11.9 9.9 19.8 15.3C326.4 497.9 353.7 512 384 512s57.5-14.1 76.2-26.8c7.9-5.4 14.6-10.8 19.8-15.3c5.1 4.5 11.9 9.9 19.8 15.3c15 10.2 35.4 21.2 58.5 25.2c9.7 1.7 17.7-6.4 17.7-16.2c0-7.9-6.4-14.1-14.1-15.7c-16-3.1-31.3-11.1-44.1-19.8c-9.8-6.7-18-14-26-21.9c-6.6-6.5-17.2-6.5-23.7 0c-7.9 7.9-16.1 15.2-25.9 21.9C425.6 470 404.9 480 384 480s-41.7-9.9-58.2-21.2c-9.8-6.7-18-14-26-21.9c-6.6-6.5-17.2-6.5-23.7 0c-7.9 7.9-16.1 15.2-25.9 21.9C233.6 470 212.9 480 192 480z" />
                        </svg>
                      </span>
                      <h6 className="whatwedo-list-title">
                        Leakages in Facade
                      </h6>
                    </li>
                    <li className="whatwedo-list-item" onClick={() => navigate('/cracks-plaster')}>
                      <span className="whatwedo-list-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                          <path
                            d="M298.6 4c-6-5.3-15.1-5.3-21.2 0L5.4 244c-6.6 5.8-7.3 16-1.4 22.6s16 7.3 22.6 1.4L64 235V432c0 44.2 35.8 80 80 80H432c44.2 0 80-35.8 80-80V235l37.4 33c6.6 5.8 16.7 5.2 22.6-1.4s5.2-16.7-1.4-22.6L298.6 4zM96 432V206.7L288 37.3 480 206.7V432c0 26.5-21.5 48-48 48H312.6l-50.4-75.6 82.7-55.1c4-2.7 6.6-7 7-11.7s-1.2-9.5-4.6-12.9l-96-96c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6l82.2 82.2-79.8 53.2c-7.4 4.9-9.3 14.8-4.4 22.2L274.1 480H144c-26.5 0-48-21.5-48-48z" />
                        </svg>
                      </span>
                      <h6 className="whatwedo-list-title">
                        Cracks in Plaster
                      </h6>
                    </li>
                    <li className="whatwedo-list-item" onClick={() => navigate('/waterproofing-systems')}>
                      <span className="whatwedo-list-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                          <path
                            d="M176.1 99.3C178.6 61.7 209.8 32 248 32c26.4 0 49.4 14.2 62 35.4c2.3 3.8 6 6.5 10.3 7.5s8.9 .1 12.5-2.4c7.7-5.3 17-8.4 27.2-8.4c23 0 42.2 16.1 46.9 37.7c1.6 7.3 8.1 12.6 15.6 12.6h18.6c21.5 0 38.9 17.4 38.9 38.9s-17.4 38.9-38.9 38.9H134.9C113.4 192 96 174.6 96 153.1s17.4-38.9 38.9-38.9h25.3c8.4 0 15.4-6.6 16-15zM248 0c-50 0-91.7 35.3-101.7 82.3H134.9C95.7 82.3 64 114 64 153.1s31.7 70.9 70.9 70.9H441.1c39.1 0 70.9-31.7 70.9-70.9s-31.7-70.9-70.9-70.9h-6.8C422.5 52.8 393.7 32 360 32c-11.1 0-21.7 2.3-31.3 6.4C309.6 15 280.6 0 248 0zM141.3 280.9c4.9-7.4 2.9-17.3-4.4-22.2s-17.3-2.9-22.2 4.4l-64 96c-4.9 7.4-2.9 17.3 4.4 22.2s17.3 2.9 22.2-4.4l64-96zM192 480c-20.9 0-41.7-9.9-58.2-21.2c-9.7-6.6-18-14-25.9-21.9c-6.6-6.5-17.2-6.5-23.8 0c-7.9 7.9-16.1 15.2-25.9 21.9c-12.8 8.7-28.1 16.6-44 19.8C6.4 480.1 0 486.4 0 494.2c0 9.8 8 17.9 17.7 16.2c23.1-4 43.6-15 58.5-25.2c7.9-5.4 14.6-10.8 19.8-15.3c5.1 4.5 11.9 9.9 19.8 15.3C134.4 497.9 161.7 512 192 512s57.5-14.1 76.2-26.8c7.9-5.4 14.6-10.8 19.8-15.3c5.1 4.5 11.9 9.9 19.8 15.3C326.4 497.9 353.7 512 384 512s57.5-14.1 76.2-26.8c7.9-5.4 14.6-10.8 19.8-15.3c5.1 4.5 11.9 9.9 19.8 15.3c15 10.2 35.4 21.2 58.5 25.2c9.7 1.7 17.7-6.4 17.7-16.2c0-7.9-6.4-14.1-14.1-15.7c-16-3.1-31.3-11.1-44.1-19.8c-9.8-6.7-18-14-26-21.9c-6.6-6.5-17.2-6.5-23.7 0c-7.9 7.9-16.1 15.2-25.9 21.9C425.6 470 404.9 480 384 480s-41.7-9.9-58.2-21.2c-9.8-6.7-18-14-26-21.9c-6.6-6.5-17.2-6.5-23.7 0c-7.9 7.9-16.1 15.2-25.9 21.9C233.6 470 212.9 480 192 480zM472.9 258.7c-7.4-4.9-17.3-2.9-22.2 4.4l-64 96c-4.9 7.4-2.9 17.3 4.4 22.2s17.3 2.9 22.2-4.4l64-96c4.9-7.4 2.9-17.3-4.4-22.2zM253.3 280.9c4.9-7.4 2.9-17.3-4.4-22.2s-17.3-2.9-22.2 4.4l-64 96c-4.9 7.4-2.9 17.3 4.4 22.2s17.3 2.9 22.2-4.4l64-96zm107.6-22.2c-7.4-4.9-17.3-2.9-22.2 4.4l-64 96c-4.9 7.4-2.9 17.3 4.4 22.2s17.3 2.9 22.2-4.4l64-96c4.9-7.4 2.9-17.3-4.4-22.2z" />
                        </svg>
                      </span>
                      <h6 className="whatwedo-list-title">
                        Waterproofing
                      </h6>
                    </li>

                    <li className="whatwedo-list-item" onClick={() => navigate('/plumbing-defects')}>
                      <span className="whatwedo-list-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                          <path
                            d="M272 32c0-8.8-7.2-16-16-16s-16 7.2-16 16V80H144c-8.8 0-16 7.2-16 16s7.2 16 16 16h96v80H32V176c0-8.8-7.2-16-16-16s-16 7.2-16 16v32V432v32c0 8.8 7.2 16 16 16s16-7.2 16-16V448H480v16c0 8.8 7.2 16 16 16s16-7.2 16-16V432 208 176c0-8.8-7.2-16-16-16s-16 7.2-16 16v16H272V112h96c8.8 0 16-7.2 16-16s-7.2-16-16-16H272V32zM256 224H480V416H32V224H256z" />
                        </svg>
                      </span>
                      <h6 className="whatwedo-list-title">
                        Plumbing
                      </h6>
                    </li>
                    <li className="whatwedo-list-item" onClick={() => navigate('/painting')}>
                      <span className="whatwedo-list-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                          <path
                            d="M256 352c-17.7 0-32 14.3-32 32v64c0 17.7-14.3 32-32 32s-32-14.3-32-32V384c0-17.7-14.3-32-32-32H80c-26.5 0-48-21.5-48-48V288H352v16c0 26.5-21.5 48-48 48H256zm96-96H32V64c0-17.7 14.3-32 32-32H96V144c0 8.8 7.2 16 16 16s16-7.2 16-16V32h64v80c0 8.8 7.2 16 16 16s16-7.2 16-16V32h96c17.7 0 32 14.3 32 32V256zM80 384H96h32v32 32c0 35.3 28.7 64 64 64s64-28.7 64-64V416 384h32 16c44.2 0 80-35.8 80-80V64c0-35.3-28.7-64-64-64H64C28.7 0 0 28.7 0 64V304c0 44.2 35.8 80 80 80z" />
                        </svg>
                      </span>
                      <h6 className="whatwedo-list-title">
                        Painting
                      </h6>
                    </li>


                  </ul>
                </div>
              </div>
            </div>


          </div>

        </section>

        {/* <!-- who we are --> */}
        <section className="whoweare-wrap section-wrap">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 col-lg-7 order-1 order-lg-3">
                <div className="whoweare-header-wrap ps-0 ps-lg-4">
                  <h3 className="whoweare-title section-title">
                    Who We Are
                  </h3>

                  <p className="whoweare-subtitle mb-4 mb-lg-5">
                    We are a team of experienced professionals dedicated to
                    providing expert guidance and solutions for all your building repair needs.
                    <br />
                    We have a deep
                    understanding of the construction industry and possess extensive knowledge in various
                    aspects of building repairs, including structural issues, waterproofing, painting, and
                    redevelopment projects.
                  </p>

                  <button className="primary-btn" onClick={() => navigate('/who-we-are')}>
                    Know Us More
                  </button>
                </div>
              </div>
              <div className="col-12 col-lg-5 order-3 order-lg-1">
                <div className="whoweare-body">
                  <div className="whoweare-img-wrap">
                    <img src={Whoweareimg} className="whoweare-img" alt="img of a building in repair" />
                  </div>
                </div>
              </div>
            </div>



          </div>
        </section>

        {/* <!-- Our Team --> */}
        <section className="ourteam-wrap section-wrap">
          <div className="container">
            <div className="ourteam-header-wrap">
              <h3 className="section-title text-center">
                Our Team
              </h3>

              <p className="section-subtitle text-center">
                Our team is comprised of highly skilled individuals who are committed to delivering exceptional
                results and ensuring client satisfaction.
              </p>
            </div>
            <div className="ourteam-card-wrap ">
              <div className="ourteam-card ourteam-card-center">
                <h4 className="card-title">
                  Ahtesham Inamdar
                </h4>
                <span className="card-subtitle">
                  B.TECH. (Civil)
                </span>
                <p className="card-body">
                  Senior Project Manager with 7+ years of experience in Structural Repairs and Rehabilitation of Buildings.
                  Work Profile consists of renowned projects under reputable consultant.
                </p>
              </div>

              <div className="ourteam-card ourteam-card-new-col">
                <h4 className="card-title">
                  Faraz Inamdar
                </h4>
                <span className="card-subtitle">
                  (B. COM)
                </span>
                <p className="card-body">
                  With three years of experience as a senior accountant. Expertise in managing billing processes and conduct
                  verifications.
                </p>
              </div>

              <div className="ourteam-card">
                <h4 className="card-title">
                  Junaid Maste
                </h4>
                <span className="card-subtitle">
                  (M.E.) – Structural Engineer
                </span>
                <p className="card-body">
                  Ten years of professional experience in the field of structural repairs and rehabilitation of buildings,
                  utilizing the most recent methods.
                </p>
              </div>

              <div className="ourteam-card">
                <h4 className="card-title">
                  Tehsin Kazi
                </h4>
                <span className="card-subtitle">
                  (M.TECH) – Transportation Engineering
                </span>
                <p className="card-body">
                  Having 9+ years of work experience the field of Repairs & Rehabilitation, using all the latest techniques.
                </p>
              </div>

              <div className="ourteam-card">
                <h4 className="card-title">
                  Asif Iqbal Khan
                </h4>
                <span className="card-subtitle">
                  (M. E.) – Structural Engineer
                </span>
                <p className="card-body">
                  20 years of
                  professional experience in the field of structural repairs and
                  rehabilitation of buildings, utilizing the most recent methods. Additionally,
                  it is essential for designing the buildings and engineered waterproofing and
                  repair applications.
                </p>
              </div>
            </div>
          </div>
        </section>


        {/* <!-- Contact Us --> */}
        <div className="contactus-wrap section-wrap" id='contact-us'>
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-6">
                <div className="contactus-header-wrap">
                  <h3 className="section-title mb-4">
                    Contact Us
                  </h3>

                  <p className="mb-4">
                    Reach Out to us via Phone or Fill the form and we'll get back to you soon!
                  </p>

                  <p className="contactus-info">
                    <svg className="contactus_icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path
                        d="M375.8 275.2c-16.4-7-35.4-2.4-46.7 11.4l-33.2 40.6c-46-26.7-84.4-65.1-111.1-111.1L225.3 183c13.8-11.3 18.5-30.3 11.4-46.7l-48-112C181.2 6.7 162.3-3.1 143.6 .9l-112 24C13.2 28.8 0 45.1 0 64v0C0 295.2 175.2 485.6 400.1 509.5c9.8 1 19.6 1.8 29.6 2.2c0 0 0 0 0 0c0 0 .1 0 .1 0c6.1 .2 12.1 .4 18.2 .4l0 0c18.9 0 35.2-13.2 39.1-31.6l24-112c4-18.7-5.8-37.6-23.4-45.1l-112-48zM441.5 464C225.8 460.5 51.5 286.2 48.1 70.5l99.2-21.3 43 100.4L154.4 179c-18.2 14.9-22.9 40.8-11.1 61.2c30.9 53.3 75.3 97.7 128.6 128.6c20.4 11.8 46.3 7.1 61.2-11.1l29.4-35.9 100.4 43L441.5 464zM48 64v0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0s0 0 0 0">
                      </path>
                    </svg>

                    <strong> <small>Contact</small></strong>: <a href="tel:9930349223">9930349223
                    </a>
                    {/* / <a href="tel:9833682313">9833682313</a> */}
                  </p>
                  <p className="contactus-info">
                    <svg xmlns="http://www.w3.org/2000/svg" className="contactus_icon" viewBox="0 0 512 512">
                      <path
                        d="M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z">
                      </path>
                    </svg>
                    <strong> <small>Email</small></strong>: <a
                      href="mailto:techadviseconsultants@gmail.com">techadviseconsultants@gmail.com</a>
                  </p>
                  <p className="contactus-info">
                    <svg className="contactus_icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                      <path
                        d="M336 192c0-79.5-64.5-144-144-144S48 112.5 48 192c0 12.4 4.5 31.6 15.3 57.2c10.5 24.8 25.4 52.2 42.5 79.9c28.5 46.2 61.5 90.8 86.2 122.6c24.8-31.8 57.8-76.4 86.2-122.6c17.1-27.7 32-55.1 42.5-79.9C331.5 223.6 336 204.4 336 192zm48 0c0 87.4-117 243-168.3 307.2c-12.3 15.3-35.1 15.3-47.4 0C117 435 0 279.4 0 192C0 86 86 0 192 0S384 86 384 192zm-160 0a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm-112 0a80 80 0 1 1 160 0 80 80 0 1 1 -160 0z">
                      </path>
                    </svg>
                    <strong><small> Address</small></strong>: <a href="https://maps.app.goo.gl/vsuGPyoCcRWdtqvL8">Sai
                      Ganesh
                      CHSL, Plot No 88A,
                      Sector – 50 E, Seawoods,
                      Nerul – 400 706</a>
                  </p>

                </div>
              </div>
              <div className="col-12 col-lg-6">

                <div className="contactus-body row justify-content-center">

                  <div className="col-12 col-lg-10 ">
                    <form action="" className="mt-4" onSubmit={sendEmail} ref={form}>
                      <div className="mb-3">
                        <label htmlFor="name">Name</label>
                        <input type="text" name='from_name' value={formContent.name} className="form-control rounded-0" id="name"
                          onChange={(e) => setformContent({ ...formContent, name: e.target.value })}
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="mail">Email</label>
                        <input type="email" value={formContent.email} name='user_mail' className="form-control rounded-0" id="mail"
                          onChange={(e) => setformContent({ ...formContent, email: e.target.value })} />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="message">Message</label>
                        <textarea name='message' value={formContent.message} className="form-control rounded-0" id="message" rows="3" onChange={(e) => setformContent({ ...formContent, message: e.target.value })}></textarea>
                      </div>
                      <div className="text-end mt-3 mt-lg-4">
                        <button className="primary-btn m-0 px-5" type='submit'>
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>

                </div>
              </div>
            </div>



          </div>
        </div>




      </main>
      <Modal isOpen={modalConfig.isOpen} type={modalConfig.type} />

      <Footer />

    </>

  )
}
