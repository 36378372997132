import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import WhatWeDo2 from '../images/leakage.jpg'
import { Link } from 'react-router-dom'

export default function Leakages() {


  return (
    <>
      <Header bannerStyle={'white-navbar'} />

      <main className='services-wrap'>
        <section className='whatwedo-hero-wrap '>
          <div className='container'>
            <div className='whatwedo-page-header'>
              <span className='text-accent inner-page-subtitle'>Leakages in Facade</span>
              <h1 className='whatwedo-page-title'>
                Addressing Structural Vulnerabilities.
              </h1>
            </div>
          </div>
        </section>

        <section className='services-section-wrap section-wrap'>
          <div className="container services-section-grid">
            <div>

              <div className="services-header-wrap">
                <h3 className='section-title'>
                  Leakages in Facade
                </h3>
              </div>
              <div className='services-body'>
                <p className='services-body-text'>
                  Leakage in a facade can have various causes, and identifying the specific cause is crucial for determining the appropriate repair recommendation. Some common causes of facade leakage include:
                  Poor waterproofing: Inadequate or deteriorated waterproofing membranes or sealants can allow water to penetrate the facade.
                  Defective joints or seals: Improperly installed or deteriorated joints, seals, or flashing can create openings for water infiltration.
                  Cracks or gaps: Structural movement, settling, or thermal expansion and contraction can lead to cracks or gaps in the facade, allowing water to enter.

                </p>
                <div className='services-section-img'>
                  <img src={WhatWeDo2} alt="" />
                </div>
                <p className='services-body-text'>
                  To address facade leakage, the following repair recommendations may be considered:
                  <br />
                  <br />
                  <p className='services-body-list'>
                    1. <b> Inspection and assessment</b>: A thorough inspection by qualified professionals is necessary to identify the specific cause of the leakage and assess the extent of the damage.

                  </p>
                  <p className='services-body-list'>
                    2. <b> Repair or replacement of waterproofing systems</b>: Defective or deteriorated waterproofing membranes, sealants, or coatings should be repaired or replaced to restore the facade's water resistance.

                  </p>
                  <p className='services-body-list'>

                    3. <b> Joint and seal repair </b>: Damaged or deteriorated joints, seals, or flashing should be repaired or replaced to ensure proper sealing and prevent water infiltration.

                  </p>
                  <p className='services-body-list'>

                    4.  <b> Crack and gap sealing </b>: Cracks or gaps in the facade should be properly sealed using appropriate sealants or fillers to prevent water penetration.

                  </p>
                  <p className='services-body-list'>

                    5. <b>Improving drainage</b>:Ensuring proper functioning of drainage systems, including gutters, downspouts, and weep holes, is essential to redirect water away from the facade.
                  </p>
                </p>
                <p className='services-body-text'>
                  It is important to consult with experienced professionals, specializing in waterproofing, to accurately diagnose the cause of the leakage and determine the most suitable repair approach for your specific situation.
                </p>
              </div>
            </div>

            <div className='services-moreservice-wrap'>
              <h5 className='services-moreservice-title'>More Services</h5>
              <ul className='services-moreservice-body'>
                <li><Link to={'/structural-deterioration'}>Structural Deterioration</Link></li>
                {/* <li><Link to={'/leakages-facade'}>Leakages in Facade</Link></li> */}
                <li><Link to={'/cracks-plaster'}>Cracks in plaster</Link></li>
                <li><Link to={'/waterproofing-systems'}>Waterproofing Systems</Link></li>
                <li><Link to={'/plumbing-defects'}>Plumbing Defects</Link></li>
                <li><Link to={'/painting'}>Painting</Link></li>
              </ul>
            </div>

          </div>
        </section>


      </main>

      <Footer />
    </>
  )

}
