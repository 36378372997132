import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import WhoWeAreImg from '../images/whoWeAreAbtus.jpg'
import { Helmet } from 'react-helmet'

export default function WhoWeAre() {
	return (
		<>

			<Helmet>
				<title>Tech Advise Consultants | Who We Are</title>
				<meta name="description" content="Discover the essence of our company and team at TechAdvise Consultants. Learn about our mission, values, and dedication to providing top-notch consultancy services. Explore our expertise and commitment to delivering innovative solutions tailored to your needs." />
			</Helmet>

			<Header bannerStyle={'white-navbar'} />

			<main className='whatwedo-page-wrap'>
				<section className='whatwedo-hero-wrap '>
					<div className='container'>
						<div className='whatwedo-page-header'>
							<span className='text-accent inner-page-subtitle'>Who we are</span>
							<h1 className='whatwedo-page-title'>
								Discover Our Story.
							</h1>
						</div>
					</div>
				</section>


				<section className="whoweare-wrap section-wrap mt-5">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-12 col-lg-7 order-1 order-lg-3">
								<div className="whoweare-header-wrap ps-0 ps-lg-4">
									<h3 className="whoweare-title section-title">
										About Us
									</h3>

									<p className="whoweare-subtitle mb-4 mb-lg-5">
										TAC is a team of experienced professionals dedicated to providing expert guidance and solutions for building and repair. With over a decade of experience and deep understanding of the construction industry, We possess extensive knowledge in various aspects of building repairs, including structural issues, waterproofing, painting, and redevelopment projects.
										<br />
										<br />
										We pride ourselves on our professionalism, attention to detail, and ability to provide innovative solutions to complex building repair challenges. Our goal is to assist clients in making informed decisions, navigating through legal and regulatory requirements, and optimizing the repair process to achieve the best possible outcomes. With our expertise and personalized approach, we strive to be a trusted partner for our clients, providing them with the guidance and support they need to successfully complete their building repair projects
									</p>
								</div>
							</div>
							<div className="col-12 col-lg-5 order-3 order-lg-1">
								<div className="whoweare-body">
									<div className="whoweare-img-wrap">
										<img src={WhoWeAreImg} className="whoweare-img" style={{ objectFit: 'cover' }} alt="img of a building in repair" />
									</div>
								</div>
							</div>
						</div>



					</div>
				</section>


				{/* <section className=' container'>
					<div className='ourvision-wrap p-4'>
						<div className="ourvision-header-wrap">
							<h4 className='section-title'>
								Our Vision
							</h4>
						</div>

						<div className='ourvision-body'>

							<h5>
								To be a trusted advisor and partner that provides expert guidance during repair process
							</h5>

							<hr className='text-accent' />
							<p>
								We are committed to excellence, professionalism, and customer satisfaction, ensuring that our clients receive the best possible outcomes for their building repair projects.
							</p>
						</div>

					</div>

				</section> */}


				<section className='whychooseus-wrap section-wrap mb-5'>

					<div className="container">
						<div className="whychooseus-header-wrap">
							<h3 className='section-title'>
								Why Choose Us?
							</h3>
							<p className='section-subtitle'>
								We conduct thorough assessments, propose corrective actions, guide contractor selection, oversee execution, and ensure essential documentation. Achieved excellence as a building repairs consultant, there are several key aspects to focus on what makes us special.
							</p>
						</div>


						{/* 2.	Diagnostic Skills
3.	Solution-Oriented Approach: 
4.	Communication and Collaboration: 
5.	Continuous Improvement:.
6.	Ethical Conduct */}

						<div className='whychooseus-body'>
							<div className='whychooseus-card-wrap'>
								<div className='whychooseus-card'>
									<div className='whychooseus-card-icon'>
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M0 88C0 39.4 39.4 0 88 0H392c30.9 0 56 25.1 56 56V344c0 22.3-13.1 41.6-32 50.6V464h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H80c-44.2 0-80-35.8-80-80c0-2.7 .1-5.4 .4-8H0V88zM80 400c-17.7 0-32 14.3-32 32s14.3 32 32 32H368V400H80zM48 358.7c9.8-4.3 20.6-6.7 32-6.7H392c4.4 0 8-3.6 8-8V56c0-4.4-3.6-8-8-8H88C65.9 48 48 65.9 48 88V358.7zM152 112H328c13.3 0 24 10.7 24 24s-10.7 24-24 24H152c-13.3 0-24-10.7-24-24s10.7-24 24-24zm0 80H328c13.3 0 24 10.7 24 24s-10.7 24-24 24H152c-13.3 0-24-10.7-24-24s10.7-24 24-24z" /></svg>

									</div>
									<div className='whychooseus-card-body'>
										<h6 className='whychooseus-card-title'>
											Expertise and Knowledge
										</h6>
									</div>
								</div>
								<div className='whychooseus-card'>
									<div className='whychooseus-card-icon'>
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M142.5 15.6c4.7 12.4-1.6 26.2-14 30.9L85.2 62.7c-3.1 1.2-5.2 4.2-5.2 7.5V200c0 57.4 46.6 104 104 104h8 8c57.4 0 104-46.6 104-104V70.2c0-3.3-2.1-6.3-5.2-7.5L255.6 46.5c-12.4-4.7-18.7-18.5-14-30.9s18.5-18.7 30.9-14l43.2 16.2C337.5 25.9 352 46.8 352 70.2V200c0 78.5-59.5 143.1-135.8 151.1C219.9 414.1 272.1 464 336 464c66.3 0 120-53.7 120-120V268.3c-32.5-10.2-56-40.5-56-76.3c0-44.2 35.8-80 80-80s80 35.8 80 80c0 35.8-23.5 66.1-56 76.3V344c0 92.8-75.2 168-168 168c-90.4 0-164.1-71.4-167.8-160.8C91.7 343.3 32 278.6 32 200V70.2c0-23.3 14.5-44.2 36.3-52.4L111.6 1.5c12.4-4.7 26.2 1.6 30.9 14zM480 160a32 32 0 1 0 0 64 32 32 0 1 0 0-64z" /></svg>
									</div>
									<div className='whychooseus-card-body'>
										<h6 className='whychooseus-card-title'>
											Diagnostic Skills
										</h6>
									</div>
								</div>
								<div className='whychooseus-card'>
									<div className='whychooseus-card-icon'>
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M320 64H280h-9.6C263 27.5 230.7 0 192 0s-71 27.5-78.4 64H104 64C28.7 64 0 92.7 0 128V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64zM80 112v24c0 13.3 10.7 24 24 24h88 88c13.3 0 24-10.7 24-24V112h16c8.8 0 16 7.2 16 16V448c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V128c0-8.8 7.2-16 16-16H80zm88-32a24 24 0 1 1 48 0 24 24 0 1 1 -48 0zm3.3 155.3c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L112 249.4 99.3 236.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6l24 24c6.2 6.2 16.4 6.2 22.6 0l48-48zM192 272c0 8.8 7.2 16 16 16h64c8.8 0 16-7.2 16-16s-7.2-16-16-16H208c-8.8 0-16 7.2-16 16zm-32 96c0 8.8 7.2 16 16 16h96c8.8 0 16-7.2 16-16s-7.2-16-16-16H176c-8.8 0-16 7.2-16 16zm-48 24a24 24 0 1 0 0-48 24 24 0 1 0 0 48z" /></svg>
									</div>
									<div className='whychooseus-card-body'>
										<h6 className='whychooseus-card-title'>
											Solution-Oriented Approach
										</h6>
									</div>
								</div>
								<div className='whychooseus-card'>
									<div className='whychooseus-card-icon'>
										<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M144 128a80 80 0 1 1 160 0 80 80 0 1 1 -160 0zm208 0A128 128 0 1 0 96 128a128 128 0 1 0 256 0zM269.7 352c65.7 0 120.1 48.7 129 112H49.3c8.9-63.3 63.3-112 129-112h91.4zm-91.4-48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H224 178.3zM625 177c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L625 177z" /></svg>
									</div>
									<div className='whychooseus-card-body'>
										<h6 className='whychooseus-card-title'>
											Ethical Conduct
										</h6>
									</div>
								</div>

							</div>


						</div>
					</div>


				</section>



				<section className='ourvalues-wrap section-wrap my-5'>
					<div className='container'>
						<div className='ourvalues-header-wrap'>
							<h3 className='section-title'>Our Values</h3>
							<p className='section-subtitle w-100 w-lg-50 mx-auto'>
								Building repairs consultants, like any professionals, we uphold strong work ethics in our field. The some key aspects of strong work ethics that building repairs consultants.
								<br /> We demonstrate:
							</p>
						</div>
						<div className='ourvalues-body'>
							<div className='row '>
								<div className='col-12 col-md-6 col-lg-4 gx-5 gy-5'>
									<div className='ourvalues-card'>
										<div className='ourvalues-top-wrap'>
											<span className='ourvalues-number'>01</span>
											<span className='ourvalues-overlap'>Professionalism</span>
										</div>
										<div className='ourvalues-text'>
											We maintain a high level of professionalism in our interactions with clients, colleagues, and stakeholders. This includes being punctual, reliable, and respectful in all communications and engagements.
										</div>
									</div>

								</div>
								<div className='col-12 col-md-6 col-lg-4 gx-5 gy-5'>
									<div className='ourvalues-card'>
										<div className='ourvalues-top-wrap'>
											<span className='ourvalues-number'>02</span>
											<span className='ourvalues-overlap'>Integrity</span>
										</div>
										<div className='ourvalues-text'>
											We adhere to ethical standards and act with honesty and integrity in all aspects of the work. We provide accurate information, avoid conflicts of interest, and prioritize the best interests of the clients.
										</div>
									</div>

								</div>

								<div className='col-12 col-md-6 col-lg-4 gx-5 gy-5'>
									<div className='ourvalues-card'>
										<div className='ourvalues-top-wrap'>
											<span className='ourvalues-number'>03</span>
											<span className='ourvalues-overlap'>Competence</span>
										</div>
										<div className='ourvalues-text'>
											We as a consultant continuously enhance our knowledge and skills to stay updated with the latest industry practices and regulations, striving for excellence in our work and deliver high-quality services to clients.
										</div>
									</div>

								</div>

								<div className='col-12 col-md-6 col-lg-4 gx-5 gy-5'>
									<div className='ourvalues-card'>
										<div className='ourvalues-top-wrap'>
											<span className='ourvalues-number'>04</span>
											<span className='ourvalues-overlap'>Confidentiality</span>
										</div>
										<div className='ourvalues-text'>
											Consultants often have access to sensitive information about clients and their buildings. It is crucial for to maintain strict confidentiality and protect the privacy of the clients, ensuring that information is not disclosed without proper authorization.
										</div>
									</div>

								</div>

								<div className='col-12 col-md-6 col-lg-4 gx-5 gy-5'>
									<div className='ourvalues-card'>
										<div className='ourvalues-top-wrap'>
											<span className='ourvalues-number'>05</span>
											<span className='ourvalues-overlap'>Communication</span>
										</div>
										<div className='ourvalues-text'>
											Effective communication is essential. We actively listen to clients, understand their needs, and provide clear and concise explanations of technical concepts and recommendations. Timely and transparent communication helps build trust and ensures that clients are well-informed throughout the process.
										</div>
									</div>

								</div>

								<div className='col-12 col-md-6 col-lg-4 gx-5 gy-5'>
									<div className='ourvalues-card'>
										<div className='ourvalues-top-wrap'>
											<span className='ourvalues-number'>06</span>
											<span className='ourvalues-overlap'>Accountability</span>
										</div>
										<div className='ourvalues-text'>
											We take responsibility for our actions and deliver on commitments. We are accountable for the accuracy of our assessments, the effectiveness of recommendations, and the successful completion of projects.
										</div>
									</div>

								</div>
							</div>

						</div>
					</div>

				</section>


			</main>
			<Footer />
		</>
	)
}
