import React, { useEffect, useState } from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import { completedArr, onGoingArr } from '../../pages/project/ProjectData'
import { Helmet } from 'react-helmet'

export default function OurProjects() {

  // var projects = [...completedArr, ...onGoingArr];


  const [projects, setprojects] = useState([...completedArr, ...onGoingArr])

  const [filteredData, setfilteredData] = useState(projects)

  const [searchText, setSearchText] = useState('')



  useEffect(() => {

    var tempArr = [];

    if (searchText.length === 0) {
      setfilteredData(projects)

    } else {
      projects.map((x) => {
        if (x.name.toLowerCase().includes(searchText.toLowerCase())) {
          tempArr.push(x)
        }
      })
      setfilteredData(tempArr)
    }


  }, [searchText])


  return (
    <>
      <Helmet>
        <title>Tech Advise Consultants | Our Projects</title>
        <meta name="description" content="Explore a showcase of our latest projects and successes at Tech Advise Consultants. From innovative solutions to client testimonials, discover how we deliver results and exceed expectations. Get inspired and see how we can help your business thrive with our proven track record of excellence." />
      </Helmet>


      <Header bannerStyle={'white-navbar'} />
      <main className='ourprojects-wrap'>
        <section className='whatwedo-hero-wrap '>
          <div className='container'>
            <div className='whatwedo-page-header'>
              <span className='text-accent inner-page-subtitle'>
                Our Projects
              </span>
              <h1 className='whatwedo-page-title'>
                Discover Our Endeavors.
              </h1>
            </div>
          </div>
        </section>
        <section className='ourprojects-section-wrap section-wrap'>
          <div className="container">
            <div className='ourprojects-section-header'>
              <h3 className='section-title text-center'>
                Our Projects
              </h3>
            </div>
            <div className='ourprojects-section-content'>
              <div className='ourprojects-section-search'>

                <div className="input-group mb-3">
                  <input type="text" className="form-control" placeholder='Search Projects' onChange={(e) => setSearchText(e.target.value)} />
                  {/* <button className="btn btn-outline" type="button" >

                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M368 208A160 160 0 1 0 48 208a160 160 0 1 0 320 0zM337.1 371.1C301.7 399.2 256.8 416 208 416C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208c0 48.8-16.8 93.7-44.9 129.1L505 471c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0L337.1 371.1z" /></svg>
                </button>
                <button className="btn btn-outline" type="button" >

                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M0 73.7C0 50.7 18.7 32 41.7 32H470.3c23 0 41.7 18.7 41.7 41.7c0 9.6-3.3 18.9-9.4 26.3L336 304.5V447.7c0 17.8-14.5 32.3-32.3 32.3c-7.3 0-14.4-2.5-20.1-7l-92.5-73.4c-9.6-7.6-15.1-19.1-15.1-31.3V304.5L9.4 100C3.3 92.6 0 83.3 0 73.7zM55 80L218.6 280.8c3.5 4.3 5.4 9.6 5.4 15.2v68.4l64 50.8V296c0-5.5 1.9-10.9 5.4-15.2L457 80H55z" /></svg>
                </button> */}
                </div>
              </div>
              <div className='ourprojects-section-cards'>
                {filteredData && filteredData.map((item, i) => (
                  <div className='ourprojects-section-card' key={i}>
                    <h5 className='ourprojects-card-title'>{item.name}</h5>
                    <div className='ourprojects-card-subtitle'>
                      <b> Address </b>: {item.address}
                    </div>
                    <div className='ourprojects-card-details'>
                      <b>Details </b>: {item.details}
                    </div>
                    <div className='ourprojects-card-scope'>
                      {item.scope.split(',').map((el, x) => (

                        <span className='ourprojects-card-item' key={i + x}>
                          {el}
                        </span>
                      ))}
                      {/* <span className='ourprojects-card-item'>
                      Complete Plastering
                    </span>
                    <span className='ourprojects-card-item'>

                      Major structural repairs,
                    </span>
                    <span className='ourprojects-card-item'>

                      terrace waterproofing(conventional method),
                    </span> */}
                    </div>

                  </div>
                ))}


                {/* <div className='ourprojects-section-card'>
                <h5 className='ourprojects-card-title'>Shelter Arcade CHSL</h5>
                <div className='ourprojects-card-subtitle'>
                  <b> Address </b>:
                  Plot No. 26, Sector – 42, Opp. To Dmart, Seawoods, Nerul
                </div>
                <div className='ourprojects-card-details'>
                  <b>Details </b>:
                  Shopping Area + Stilt + 7 storied – 3 wings
                </div>
                <div className='ourprojects-card-scope'>
                  <span className='ourprojects-card-item'>
                    Complete Plastering
                  </span>
                  <span className='ourprojects-card-item'>

                    Major structural repairs,
                  </span>
                  <span className='ourprojects-card-item'>

                    terrace waterproofing(conventional method),
                  </span>
         
                </div>

              </div> */}

              </div>

            </div>

          </div>
        </section>
      </main>

      <Footer />
    </>
  )
}
