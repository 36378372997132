import React from 'react'
import ReactDOM from "react-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";


export default function Modal({ isOpen, type }) {

    if (isOpen === false) {
        return (<></>)
    }
    return ReactDOM.createPortal(
        <div className='modal_wrap'>
            {
                type === 'success' ? (
                    <div className='modal_content success'>
                        Email Sent Successfully!
                    </div>
                ) :
                    (
                        <div className='modal_content fail'>
                            Failed to Send Mail!
                        </div>
                    )
            }

        </div>,
        document.getElementById("modal")
    )
}
