import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import WhatWeDo2 from '../images/painting.jpg'
import { Link } from 'react-router-dom'

export default function Painting() {


  return (
    <>
      <Header bannerStyle={'white-navbar'} />

      <main className='services-wrap'>
        <section className='whatwedo-hero-wrap '>
          <div className='container'>
            <div className='whatwedo-page-header'>
              <span className='text-accent inner-page-subtitle'>
                Painting
              </span>
              <h1 className='whatwedo-page-title'>
                Transforming Spaces with Precision Painting.
              </h1>
            </div>
          </div>
        </section>

        <section className='services-section-wrap section-wrap'>
          <div className="container services-section-grid">
            <div>

              <div className="services-header-wrap">
                <h3 className='section-title'>
                  Painting
                </h3>
              </div>
              <div className='services-body'>
                <p className='services-body-text'>
                  Paint defects can occur due to various reasons such as improper surface preparation, application errors, environmental factors, or product quality issues. Common paint defects include blistering, peeling, cracking, fading, and uneven coverage.                </p>
                <div className='services-section-img'>
                  <img src={WhatWeDo2} alt="" />
                </div>
                <p className='services-body-text'>
                  To address paint defects, it is important to identify the underlying cause. This may involve inspecting the surface, assessing the application process, and considering environmental factors. Once the cause is determined, appropriate corrective measures can be taken.
                  Benefits of painting include:</p>
                <p className='services-body-text'>
                  <p className='services-body-list'>

                    1. <b> Protection </b>: Paint acts as a protective barrier against moisture, UV rays, and other environmental elements that can cause damage to surfaces. It helps prevent corrosion, rotting, and deterioration.
                    <br />
                  </p>
                  <p className='services-body-list'>

                    2. <b> Aesthetics </b>: Painting can enhance the appearance of surfaces, giving them a fresh and appealing look. It allows for customization and can be used to create different moods or styles.
                    <br />
                  </p>
                  <p className='services-body-list'>

                    3. <b> Durability </b>: High-quality paint, when applied correctly, can provide long-lasting durability. It can withstand wear and tear, extending the lifespan of surfaces.
                    <br />
                  </p>
                  <p className='services-body-list'>

                    4. <b> Maintenance </b>: Painted surfaces are generally easier to clean and maintain. They can be wiped down or washed to remove dirt, stains, or other contaminants.
                    <br />
                  </p>
                  <p className='services-body-list'>
                    5. <b> Value</b>: A well-maintained and properly painted surface can increase the value of a property. It can improve curb appeal and make a positive impression on potential buyers or tenants.
                  </p>
                </p>
                <p className='services-body-text'>
                  When considering painting, it is important to choose the right type of paint for the specific surface and purpose. Additionally, proper surface preparation and application techniques should be followed to achieve the desired results and minimize the risk of paint defects.
                </p>
              </div>
            </div>

            <div className='services-moreservice-wrap'>
              <h5 className='services-moreservice-title'>More Services</h5>
              <ul className='services-moreservice-body'>
                <li><Link to={'/structural-deterioration'}>Structural Deterioration</Link></li>
                <li><Link to={'/leakages-facade'}>Leakages in Facade</Link></li>
                <li><Link to={'/cracks-plaster'}>Cracks in plaster</Link></li>
                <li><Link to={'/waterproofing-systems'}>Waterproofing Systems</Link></li>
                <li><Link to={'/plumbing-defects'}>Plumbing Defects</Link></li>
              </ul>
            </div>

          </div>
        </section>


      </main>

      <Footer />
    </>
  )

}
