import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import WhatWeDo2 from '../images/cracksPlaster.jpg'
import { Link } from 'react-router-dom'

export default function Cracks() {


	return (
		<>
			<Header bannerStyle={'white-navbar'} />

			<main className='services-wrap'>
				<section className='whatwedo-hero-wrap '>
					<div className='container'>
						<div className='whatwedo-page-header'>

							<span className='text-accent inner-page-subtitle'>
								Cracks in Plaster
							</span>
							<h1 className='whatwedo-page-title'>
								Resolving Plaster Imperfections.
							</h1>
						</div>
					</div>
				</section>

				<section className='services-section-wrap section-wrap'>
					<div className="container services-section-grid">
						<div>

							<div className="services-header-wrap">
								<h3 className='section-title'>
									Cracks in Plaster
								</h3>
							</div>
							<div className='services-body'>
								<p className='services-body-text'>
									Cracks in plaster can occur due to a variety of reasons, including settlement, moisture, temperature fluctuations, or structural movement. Understanding the cause of the cracks is essential in determining the appropriate repair recommendation.
									If the cracks are small and superficial, they can often be repaired using simple techniques. Here are some general repair recommendations:
								</p>
								<div className='services-section-img'>
									<img src={WhatWeDo2} alt=""  />
								</div>
								<p className='services-body-text'>
									Preparation: Clean the area around the crack by removing loose plaster and debris. Use a scraper or putty knife to create a V-shaped groove along the crack to provide a better surface for the repair material to adhere to.
									Fill the crack: Apply a suitable patching compound or joint compound into the crack using a putty knife or trowel. Smooth it out and ensure it is level with the surrounding plaster. Allow it to dry according to the manufacturer's instructions.
									Sand and prime: Once the patching compound is dry, sand it lightly to achieve a smooth finish. Wipe away any dust and apply a primer to promote adhesion of the paint or finish coat.
								</p>
								<p className='services-body-text'>
									For larger or recurring cracks, it is advisable to consult a professional who can assess the underlying cause and provide a more comprehensive repair solution. They may need to address any structural issues, such as reinforcing the substrate or addressing moisture problems, before repairing the plaster.
									Remember, these recommendations are general in nature, and the specific repair approach may vary depending on the extent and cause of the cracks. It is always recommended to seek professional advice for a thorough assessment and appropriate repair solution.
								</p>
							</div>
						</div>

						<div className='services-moreservice-wrap'>
							<h5 className='services-moreservice-title'>More Services</h5>
							<ul className='services-moreservice-body'>
								<li><Link to={'/structural-deterioration'}>Structural Deterioration</Link></li>
								<li><Link to={'/leakages-facade'}>Leakages in Facade</Link></li>
								{/* <li><Link to={'/cracks-plaster'}>Cracks in plaster</Link></li> */}
								<li><Link to={'/waterproofing-systems'}>Waterproofing Systems</Link></li>
								<li><Link to={'/plumbing-defects'}>Plumbing Defects</Link></li>
								<li><Link to={'/painting'}>Painting</Link></li>
							</ul>
						</div>

					</div>
				</section>


			</main>

			<Footer />
		</>
	)

}
