import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import WhatWeDo2 from '../images/plumbing.jpg'
import { Link } from 'react-router-dom'

export default function Plumbing() {


  return (
    <>
      <Header bannerStyle={'white-navbar'} />

      <main className='services-wrap'>
        <section className='whatwedo-hero-wrap '>
          <div className='container'>
            <div className='whatwedo-page-header'>
              <span className='text-accent inner-page-subtitle'>
                Plumbing Defects
              </span>
              <h1 className='whatwedo-page-title'>
                Solving Plumbing Puzzles with Expertise.
              </h1>
            </div>
          </div>
        </section>

        <section className='services-section-wrap section-wrap'>
          <div className="container services-section-grid">
            <div>

              <div className="services-header-wrap">
                <h3 className='section-title'>
                  Plumbing Defects
                </h3>
              </div>
              <div className='services-body'>
                <p className='services-body-text'>
                  Plumbing leakage / defects can occur due to various reasons such as faulty installation, aging pipes, high water pressure, corrosion, or damage to pipe joints. To provide repair recommendations for plumbing leakage defects, it is important to assess the specific issue and its underlying cause.
                  In general, the following steps can be taken to address plumbing leakage defects:
                </p>
                <div className='services-section-img'>
                  <img src={WhatWeDo2} alt="" />
                </div>
                <p className='services-body-text'>

                  <p className='services-body-list'>
                    1. <b> Identify the source of the leak</b>: Thoroughly inspect the plumbing system to locate the exact source of the leakage. This may involve checking pipes, fittings, valves, faucets, and other plumbing components.
                  </p>

                  <p className='services-body-list'>
                    2. <b> Repair or replace damaged components </b>: Once the source of the leak is identified, repair or replace the damaged components accordingly. This may involve fixing or replacing faulty pipes, fittings, or valves.

                  </p>
                  <p className='services-body-list'>
                    3. <b> Seal or patch leaks </b>: For minor leaks, applying sealants or patches can be an effective temporary solution. However, it is important to note that these are temporary fixes and may not provide a long-term solution.

                  </p>
                  <p className='services-body-list'>

                    4. <b> Re-piping or re-routing </b>: In cases where the plumbing system is extensively damaged or outdated, re-piping or re-routing may be necessary. This involves replacing the entire plumbing system or specific sections to ensure a more reliable and durable solution.

                  </p>
                  <p className='services-body-list'>

                    5. <b> Regular maintenance</b>: To prevent future leakage defects, regular maintenance of the plumbing system is crucial. This includes checking for any signs of leaks, addressing them promptly, and conducting routine inspections and maintenance activities.
                    It is important to consult with a professional to assess the specific plumbing leakage / defects and determine the most appropriate repair recommendations based on the severity and nature of the issue. We have the expertise and knowledge to provide tailored solutions for your specific situation
                  </p>
                </p>

              </div>
            </div>

            <div className='services-moreservice-wrap'>
              <h5 className='services-moreservice-title'>More Services</h5>
              <ul className='services-moreservice-body'>
                <li><Link to={'/structural-deterioration'}>Structural Deterioration</Link></li>
                <li><Link to={'/leakages-facade'}>Leakages in Facade</Link></li>
                <li><Link to={'/cracks-plaster'}>Cracks in plaster</Link></li>
                <li><Link to={'/waterproofing-systems'}>Waterproofing Systems</Link></li>
                <li><Link to={'/painting'}>Painting</Link></li>
              </ul>
            </div>

          </div>
        </section>


      </main>

      <Footer />
    </>
  )

}
