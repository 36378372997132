import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './src1/pages/Home';
import WhatWeDo from './src1/pages/WhatWeDo';
import './src1/styles.css'
import WhoWeAre from './src1/pages/WhoWeAre';
import Painting from './src1/pages/Painting';
import Plumbing from './src1/pages/Plumbing';
import Waterproof from './src1/pages/Waterproof';
import Cracks from './src1/pages/Cracks';
import Leakages from './src1/pages/Leakages';
import Structural from './src1/pages/Structural';
import OurProjects from './src1/pages/OurProjects';

// const router = createBrowserRouter([
//   {
//     path: "/",
//     element: <Home />,
//     errorElement: <Home />,
//     children: [
//       {
//         path: "",
//         element: <Home />,
//       },
//       {
//         path: "what-we-do",
//         element: <WhatWeDo />,
//       },

//     ]
//   },

// ]);


createRoot(document.getElementById("root")).render(
  // <RouterProvider router={router} />
  <BrowserRouter>

    <div className="App">
      {/* <Header /> */}
    </div>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/what-we-do" element={<WhatWeDo />} />
      <Route path="/who-we-are" element={<WhoWeAre />} />

      <Route path="/structural-deterioration" element={<Structural />} />
      <Route path="/leakages-facade" element={<Leakages />} />
      <Route path="/cracks-plaster" element={<Cracks />} />
      <Route path="/waterproofing-systems" element={<Waterproof />} />
      <Route path="/plumbing-defects" element={<Plumbing />} />
      <Route path="/painting" element={<Painting />} />

      <Route path="/our-projects" element={<OurProjects />} />



      <Route path="*" element={<Home />} />
    </Routes>
  </BrowserRouter>
);
