import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import WhatWeDo2 from '../images/waterproofingSys.jpg'
import { Link } from 'react-router-dom'

export default function Waterproof() {


  return (
    <>
      <Header bannerStyle={'white-navbar'} />

      <main className='services-wrap'>
        <section className='whatwedo-hero-wrap '>
          <div className='container'>
            <div className='whatwedo-page-header'>
              <span className='text-accent inner-page-subtitle'>
                Waterproofing Systems
              </span>
              <h1 className='whatwedo-page-title'>
                Protecting Structures from Moisture.
              </h1>
            </div>
          </div>
        </section>

        <section className='services-section-wrap section-wrap'>
          <div className="container services-section-grid">
            <div>

              <div className="services-header-wrap">
                <h3 className='section-title'>
                  Waterproofing Systems
                </h3>
              </div>
              <div className='services-body'>
                <p className='services-body-text'>
                  Waterproofing of podiums and terraces is essential to protect the underlying structure from water damage, leaks, and moisture infiltration. Failure to properly waterproof these areas can lead to structural deterioration, algal growth, and costly repairs.                </p>
                <div className='services-section-img'>
                  <img src={WhatWeDo2} alt="" />
                </div>
                <p className='services-body-text'>
                  Causes of waterproofing issues in podiums and terraces can include poor construction practices, inadequate material strength, increase in the porosity, improper slope or drainage, and lack of maintenance. Additionally, extreme weather conditions, such as heavy rainfall or freeze-thaw cycles, can exacerbate these issues.
                  Repair recommendations for waterproofing podiums and terraces depend on the specific problem identified during a thorough assessment. However, some general recommendations include:
                </p>
                <p className='services-body-text'>

                  <p className='services-body-list'>

                    1. <b> Surface Preparation </b>: Ensure the surface is clean, free from debris, and properly prepared before applying any waterproofing materials.

                  </p>
                  <p className='services-body-list'>

                    2. <b> Crack Repair </b>: Address any cracks or gaps in the concrete or substrate using appropriate sealants or fillers to prevent water penetration.

                  </p>
                  <p className='services-body-list'>

                    3. <b> Waterproofing Membrane</b>: Apply a high-quality waterproofing membrane that is suitable for the specific conditions and requirements of the podium or terrace. This can include liquid-applied membranes, sheet membranes, or cementitious coatings.

                  </p>
                  <p className='services-body-list'>
                    4.<b> Flashing and Drainage </b>: Install proper flashing details around edges, joints, and penetrations to prevent water from seeping into vulnerable areas. Ensure adequate slope and drainage systems are in place to redirect water away from the structure.

                  </p>
                  <p className='services-body-list'>
                    5.<b> Regular Maintenance</b>: Implement a regular maintenance program to inspect and maintain the waterproofing system. This may include periodic inspections, cleaning of drains and gutters, and prompt repair of any identified issues.
                  </p>
                </p>
                <p className='services-body-text'>

                  It is crucial to consult with experienced waterproofing professionals or contractors who specialize in podium and terrace waterproofing to ensure the most appropriate repair recommendations are implemented. They can provide tailored solutions based on the specific conditions and requirements of the project.

                </p>
              </div>
            </div>

            <div className='services-moreservice-wrap'>
              <h5 className='services-moreservice-title'>More Services</h5>
              <ul className='services-moreservice-body'>
                <li><Link to={'/structural-deterioration'}>Structural Deterioration</Link></li>
                <li><Link to={'/leakages-facade'}>Leakages in Facade</Link></li>
                <li><Link to={'/cracks-plaster'}>Cracks in plaster</Link></li>
                <li><Link to={'/plumbing-defects'}>Plumbing Defects</Link></li>
                <li><Link to={'/painting'}>Painting</Link></li>
              </ul>
            </div>

          </div>
        </section>


      </main>

      <Footer />
    </>
  )

}
