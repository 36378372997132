import { v4 } from 'uuid'


export const completedArr = [
    {
        id: v4(),
        images: [
            "https://source.unsplash.com/hoJqozn7Avs",
        ],
        name: "NEW IDEAL MILAN C.H.S.LTD.",
        address: "Tardeo Road, Opp. A/C Market, Mumbai – 400 034",
        details: "Partly Stilt & Ground + 14 Floors. – 1 Wing.",
        scope: "Structural repairs, seepage & damages plaster defects, plumbing defects & Waterproofing, Painting work & other works"

    },
    {
        id: v4(),
        images: [
            "https://source.unsplash.com/hoJqozn7Avs",
        ],

        name: "ACRUTI ORCHID PARK “ E & G ” WING CHS LTD. ",
        address: "693 – 699, Mohili V5illage, Andheri Kurla Road, Sakinaka, Andheri (E),",
        details: "Partly Stilt + 8 floors – 1 Wings.",
        scope: "Patch plaster, Crack filling, External Painting, Allied Work."

    },
    {
        id: v4(),
        images: [
            "https://source.unsplash.com/hoJqozn7Avs",
        ],

        name: "NAV SHANTI NAGAR C.H.S. LTD",
        address: "98 Neapen Sea Road, Opp Videocon House, Mumbai– 400006",
        details: "Ground + 7 floors – 1 Wing ",
        scope: "Terrace Waterproofing, Patch plaster, Crack filling, External Painting, Allied Work"

    },
    {
        id: v4(),
        images: [
            "https://source.unsplash.com/hoJqozn7Avs",
        ],

        name: "DOSTI CARNATION C.H.S. LTD",
        address: "Dosti Acres, Antop Hills, Wadala East, Mumbai-400037",
        details: "Basement + Ground + 6 Floors – 2 Wings",
        scope: "Patch plaster, Crack filling , External Painting, Allied Work."

    },
    {
        id: v4(),
        images: [
            "https://source.unsplash.com/hoJqozn7Avs",
        ],

        name: "SIDDHIVINAYAK HORIZON COOP. HSG. SOC. LTD",
        address: "Veer Nariman Road, Near Century Bazaar, Prabhadevi, Mumbai- 400 025.",
        details: "Stilt + 7 floors – 1 Wings",
        scope: "Patch plaster, Crack filling, External Painting, Allied Work."

    },
    {
        id: v4(),
        images: [
            "https://source.unsplash.com/hoJqozn7Avs",
        ],

        name: "OM SUDARSHAN CHS. LTD. ",
        address: "22, L.D. Ruparel Marg, Malabar Hill, Mumbai – 400 006",
        details: "Ground + 6 floors– 1 Wings",
        scope: "Patch plaster, Crack filling , External Painting, Allied Work"

    },
    {
        id: v4(),
        images: [
            "https://source.unsplash.com/hoJqozn7Avs",
        ],

        name: "LANDMARK TOWER CO. OP. HSG. SOC. LTD.",
        address: "Ambekar Marg, Naigaon, Dadar East, Mumbai - 400 014",
        details: "03wings of 2A Wing Partly Stilt & Ground + 15 Floors and 2B & 2C Wings Stilt+ 7 Floors ",
        scope: "Structural repairs, waterproofing, plumbing defects, plaster work, seepage leakage defects"

    },

    {
        id: v4(),
        images: ["https://source.unsplash.com/hoJqozn7Avs",],
        name: "RAJYOG RESIDENCY C.H.S. LTD. ",
        address: "B 2, DR. R. P. Road, Mulund (W), Mumbai – 400 080",
        details: "Ground + 7 storied – 1 Wings ",
        scope: "Patch Re-plastering, structural repairs,  Painting with Acrylic paint"
    },
    {
        id: v4(),
        images: ["https://source.unsplash.com/hoJqozn7Avs",],
        name: "HEMRESHA C.H.S LTD. ",
        address: "Gupta Compound, V.P. Road, Mulund (W).",
        details: "Ground +8 storied – 1 wing ",
        scope: "Patchwork Replastering, structural repairs, Total replacement of PVC drain, plumbing, G.I plumbing, Terrace waterproofing (conventional brickbat method) , china mosaic on top, Synthetic textures, Painting with Acrylic paint"
    },
    {
        id: v4(),
        images: ["https://source.unsplash.com/hoJqozn7Avs",],
        name: "VASUKI C.H.S. LTD ",
        address: "7th Road, Rajawadi, Near Neelkantha Vally, Ghatkopar (E), Mumbai – 400 077 ",
        details: "Stilt +6 storied – 2 wings",
        scope: "Patchwork Replastering, structural repairs, Total replacement of PVC drain, plumbing, G.I plumbing, Terrace waterproofing (conventional brickbat method) , china mosaic on top, Synthetic textures, Painting with Acrylic paint"
    },
    {
        id: v4(),
        images: ["https://source.unsplash.com/hoJqozn7Avs",],
        name: "SAI GANESH CHSL",
        address: "Plot No. 88A, Sector – 50E, Seawoods, Nerul",
        details: "Stilt +6 storied – 1 wing ",
        scope: "Patchwork Replastering, structural repairs, terrace waterproofing, Painting with Ultima Protek"
    },
]

export const onGoingArr = [
    {
        id: v4(),
        images: ["https://source.unsplash.com/hoJqozn7Avs", "https://source.unsplash.com/hoJqozn7Avs"],
        name: "SHELTER ARCADE CHSL",
        address: "Plot No. 26, Sector – 42, Opp. To Dmart, Seawoods, Nerul",
        details: "Shopping Area + Stilt + 7 storied – 3 wings",
        scope: "Complete Plastering, Major structural repairs, terrace waterproofing(conventional method), OHWT waterproofing, Complete plumbing work, Shopping area repairs, Entire building painting , 10 years warranty of Painting"
    },
    {
        id: v4(),
        images: ["https://source.unsplash.com/hoJqozn7Avs",],
        name: "GODREJ SKY GARDEN – PETUNIA CHSL ",
        address: "Takka Colony, Panvel ",
        details: "Stilt + 7 floor – 1 wing ",
        scope: "Patchwork Re-plastering, structural repairs, Complete plumbing work, Painting with Asian Paints Ultima Protek,  10 years warranty of Painting "
    },
    {
        id: v4(),
        images: ["https://source.unsplash.com/hoJqozn7Avs",],
        name: "GODREJ SKY GARDEN – LILY CHSL ",
        address: "Takka Colony, Panvel",
        details: "Stilt + 3 floor – 1 wing ",
        scope: "Major Structural Repairs, Terrace Waterproofing, Patch Repairs , Painting with Acrylic Paint."
    },
    {
        id: v4(),
        images: ["https://source.unsplash.com/hoJqozn7Avs",],
        name: "VAISHNAV DHAM CHSL",
        address: " Plot No. 80, Sector – 50E, Seawoods, Nerul ",
        details: "Stilt + 13 storied – 1 wing",
        scope: "Patchwork Re-plastering, structural repairs, terrace waterproofing, Painting  with Asian Paints Ultima Protek , 10 years warranty of Painting"
    },

    {
        id: v4(),
        images: ["https://source.unsplash.com/hoJqozn7Avs",],
        name: "SATYAM HEIGHTS CHSL ",
        address: "Plot No. 81, Sector – 19, Kamothe",
        details: "Stilt + Podium + 14 storied – 1 wing ",
        scope: "Terrace waterproofing , Podium waterproofing with Dr. Fixit,  PU coating with 10 years warranty, Painting , Other allied works"
    },

    {
        id: v4(),
        images: ["https://source.unsplash.com/hoJqozn7Avs",],
        name: "THE SPRINGS 2 CHSL ",
        address: "PlotNo. 15, Sector – 20, Roadpali, Kalamboli ",
        details: "Stilt + Podium + 19 storied – 1 wing ",
        scope: "Patchwork Re-plastering, structural repairs Painting with ,Dulux Power flex paint,  10 years warranty of Painting"
    },
    {
        id: v4(),
        images: ["https://source.unsplash.com/hoJqozn7Avs",],
        name: "KENDRIYA VIHAR – 3 CHSL",
        address: "Sector – 11, Kharghar",
        details: "Stilt + 5 floor – 1 wing",
        scope: "Major Structural Repairs, Terrace Waterproofing, Patch Repairs, Painting with Asian Paints Ultima Protek , 10 years warranty of Painting"
    },
]